// dependencies.
import React, { useState, Fragment } from 'react'
import classNames from 'classnames'
// components.
import Button from 'src/components/button'
import ProfitCalculator from 'src/components/profit-calculator/profitCalculatorRewards'

// Styles & Images
import 'src/components/pages/earn-crypto/scss/CalculatorSection.scss'

// Partials:
const buttonIcon = (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none">
    <path opacity="0.8" d="M1 1L5.5 5.5L10 1" stroke="white" />
  </svg>
)

// Main component:
const CalculatorSection = ({ data, assets = [] }) => {
  const [showCalculator, setShowCalculator] = useState(false)

  const checkAssetsExist = assets.length > 0

  return (
    <section
      className={classNames('x__earn-crypto-page__calculator', {
        showCalculator,
      })}
    >
      <div className="x__earn-crypto-page__calculator__actions">
        <Button
          copy={data.cta.copy}
          size="largest"
          active={showCalculator}
          disabled={!checkAssetsExist}
          iconRight={buttonIcon}
          onClick={() => setShowCalculator(!showCalculator)}
        />

        <span className="x__earn-crypto-page__calculator__actions__note">
          *{' '}
          {data.disclaimer.length > 0 &&
            data.disclaimer.map((paragraph, i) => (
              <Fragment key={i}>
                {paragraph}
                {data.disclaimer.length !== i - 1 && <br />}
              </Fragment>
            ))}
        </span>
      </div>

      {checkAssetsExist && (
        <div className="x__earn-crypto-page__calculator__content">
          <h2 className="x__earn-crypto-page__calculator__heading">{data.h2}</h2>

          <ProfitCalculator assets={assets} />

          <p className="x__earn-crypto-page__calculator__note">{data.footnote}</p>
        </div>
      )}
    </section>
  )
}

export default CalculatorSection
