import {
  getAPY,
  getColors,
  getCount,
  getIcon,
  getIsUnavailable,
  getLink,
  getMarketcap,
  getName,
  getPrice,
  getTicker,
} from 'src/js/assets/utils'

import { basicToCompoundApyRate } from 'src/js/assets/constants'

const processAssetData = (dataObjects, asset, withApy, withPrice, withCurrentPrice) => {
  const colors = getColors(asset)
  const icon = getIcon(dataObjects.iconsData, asset.name)
  const name = getName(asset)
  const ticker = getTicker(asset)
  const unavailable = getIsUnavailable(dataObjects.assetsData, asset, icon)

  const link = getLink(ticker)
  const apy = getAPY(ticker, withApy)
  const price = getPrice(ticker, withPrice, withCurrentPrice)
  const marketcap = getMarketcap(ticker, withPrice)

  const isAssetWithAPY = Object.keys(withApy).includes(ticker)
  const compoundApy = `${basicToCompoundApyRate(apy).toFixed(2)}`

  if (!isAssetWithAPY) return null

  return {
    apy,
    ...(asset.assetType === 'COSMOS_LIKE' ? { compoundApy } : {}),
    colors,
    icon,
    link,
    name,
    marketcap,
    price,
    ticker,
    unavailable,
  }
}

const prepareAssetList = (dataObjects, withApy, withPrice, withCurrentPrice) => {
  const assetNames = Object.keys(dataObjects.assetsData)

  // build asset list.
  const assetsArray = assetNames.map((key) => {
    return processAssetData(
      dataObjects,
      dataObjects.assetsData[key],
      withApy,
      withPrice,
      withCurrentPrice
    )
  })

  // filter out unavailable assets and without icon, and return.
  const filteredAssets = assetsArray.filter((asset) => asset && !asset.unavailable)
  // remove duplicated assets.
  const filteredAssetsSet = filteredAssets.filter(
    (obj, i, self) => i === self.findIndex((o) => o.ticker === obj.ticker)
  )
  // assets cont.
  const filteredAssetsCount = getCount(filteredAssetsSet)

  return { assets: filteredAssetsSet, count: filteredAssetsCount }
}

export default prepareAssetList
