// dependencies.
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown'
// components.
import InputRange from 'src/components/profit-calculator/inputRange'
// utils.
import { useMedia } from 'src/js/utils/hooks'

// Styles & Images.
import 'static/components/scss/calculate-sliders.scss'

// partials.
const RestakeIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_196_2611)" />
    <g clipPath="url(#clip0_196_2611)">
      <path
        d="M13.333 27.5C14.7137 27.5 15.833 26.3807 15.833 25C15.833 23.6193 14.7137 22.5 13.333 22.5C11.9523 22.5 10.833 23.6193 10.833 25C10.833 26.3807 11.9523 27.5 13.333 27.5Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.4718 10.9417L11.8643 15.7725L16.6076 14.4508"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M11.8643 15.7725C12.6634 14.2337 13.8825 12.9532 15.3802 12.0794C16.8778 11.2057 18.5926 10.7747 20.3254 10.8364C22.0582 10.898 23.7379 11.4499 25.1697 12.4279C26.6014 13.4059 27.7264 14.7699 28.4141 16.3615C29.1018 17.9532 29.324 19.7073 29.0549 21.4202C28.7857 23.133 28.0363 24.7344 26.8936 26.0385C25.7509 27.3426 24.2617 28.2958 22.599 28.7875C20.9364 29.2793 19.1683 29.2894 17.5001 28.8166"
        stroke="white"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_196_2611"
        x1="60"
        y1="20"
        x2="20"
        y2="-20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#420BE6" />
        <stop offset="1" stopColor="#A16BB3" />
      </linearGradient>
      <clipPath id="clip0_196_2611">
        <rect width="20" height="20" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
)

const ToggleButton = ({ colors, isChecked, onChange }) => {
  const [checked, setChecked] = useState(isChecked)

  const [colorStart, colorStop] = colors

  useEffect(() => {
    onChange(checked)
  }, [checked])

  return (
    <span
      tabIndex={0}
      className={classNames('x__toggle-button', { 'x__toggle-button--checked': checked })}
      style={
        checked
          ? { backgroundImage: `linear-gradient(to right, ${colorStart} 0%, ${colorStop} 100%)` }
          : {}
      }
      onClick={() => setChecked(!checked)}
    />
  )
}

// Main component:
const ProfitCalculator = ({ assets = [], selectedTicker = 'ALGO' }) => {
  const [amount, setAmount] = useState(10000)
  const [month, setMonth] = useState(12)
  const [activeTicker, setActiveTicker] = useState(selectedTicker)

  const initialActiveAsset =
    assets.length > 0 && assets.find((asset) => asset.ticker === activeTicker)
  const [activeAsset, setActiveAsset] = useState(initialActiveAsset)

  const [compoundIsActive, setCompoundIsActive] = useState(true)

  // get active asset apy %.
  const activeRate = useMemo(() => {
    if (activeAsset && activeAsset.apy > 0) {
      if (compoundIsActive && activeAsset.compoundApy > 0)
        return parseFloat(activeAsset.compoundApy)
      return parseFloat(activeAsset.apy)
    }
    return 0
  }, [activeAsset, compoundIsActive])

  // get active asset price.
  const activePrice = useMemo(() => {
    if (activeAsset && activeAsset.price.current > 0) return parseFloat(activeAsset.price.current)
    return 0
  }, [activeAsset])

  // handle initial values change.
  const handleChangeAmount = useCallback(
    (v) => {
      const regex = /^\d+$/
      regex.test(v.toString()) ? setAmount(Math.min(Math.max(v, 0), 100000)) : setAmount(0)
    },
    [setAmount]
  )

  const handleChangeMonth = useCallback(
    (m) => {
      const regex = /^\d+$/
      regex.test(m.toString()) ? setMonth(Math.min(m, 24)) : setMonth('')
    },
    [setMonth]
  )

  const monthToDisplay = month || 1

  // fotmat currency.
  const formatterCurrency = new Intl.NumberFormat(
    typeof navigator !== 'undefined' ? navigator.language : 'en-US',
    {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }
  )

  // calculate reward amount.
  const calculatedAmount = useMemo(
    () => ((amount * activeRate) / 100 / 12) * monthToDisplay,
    [amount, activeRate, monthToDisplay]
  )

  // item props.
  const commonItemProps = useMemo(
    () => ({
      onClick: setActiveTicker,
      activeTicker,
    }),
    [activeTicker]
  )

  const decreaseTabSize = assets.length > 7
  const isSmallScreen = useMedia(`(max-width: 768px)`) // todo support css variable on server-side rendering
  const showDropdown = isSmallScreen || assets.length > 10

  useEffect(() => {
    const updatedActiveAsset =
      assets.length > 0 && assets.find((asset) => asset.ticker === activeTicker)
    setActiveAsset(updatedActiveAsset)
  }, [assets, activeTicker])

  return (
    <div>
      <div
        className={classNames('x__earn-crypto-page__dropdown', {
          hide: !showDropdown,
        })}
      >
        <Dropdown className="x__earn-crypto-page__dropdown__container">
          <Dropdown.Toggle className="x__earn-crypto-page__dropdown__button">
            <DropdownToggler asset={activeAsset} compoundIsActive={compoundIsActive} />

            <svg version="1.1" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
              <g fill="#fff" fillRule="nonzero">
                <path
                  transform="translate(5.5 3.0485) rotate(90) translate(-5.5 -3.0485)"
                  d="m8.0694 3.2926l-4.6602 4.8592c-0.13071 0.13629-0.34381 0.13751-0.47597 0.0027118-0.13216-0.1348-0.13334-0.35456-0.0026297-0.49085l4.4261-4.6152-4.4261-4.6152c-0.13071-0.13629-0.12953-0.35605 0.0026297-0.49085 0.13216-0.1348 0.34526-0.13358 0.47597 0.0027119l4.6602 4.8592c0.12984 0.13539 0.12954 0.35314-1.713e-5 0.48815l1.713e-5 -1.85e-5z"
                />
              </g>
            </svg>
          </Dropdown.Toggle>

          <Dropdown.Menu x-placement="bottom-start">
            {assets.map((asset) => (
              <DropdownItem
                key={asset.ticker}
                asset={asset}
                isActive={asset.ticker === activeTicker}
                compoundIsActive={compoundIsActive}
                className="dropdown-item"
                {...commonItemProps}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div
        className={classNames('x__earn-crypto-page-tabs', {
          hide: showDropdown,
        })}
      >
        {assets.map((asset) => (
          <TabItem
            key={asset.ticker}
            asset={asset}
            isActive={asset.ticker === activeTicker}
            decreaseTabSize={decreaseTabSize}
            {...commonItemProps}
          />
        ))}
      </div>
      <div
        className={classNames('x-calculate-sliders', {
          'x-calculate-sliders--with-dropdown': showDropdown,
        })}
      >
        <div className="x-calculate-sliders__sliders">
          <div className="x-calculate-sliders__slider">
            <div className="x-calculate-sliders__slider-title">
              <div className="x-calculate-sliders__heading">Deposit Amount</div>

              <input
                className="x-calculate-sliders__value"
                id="daiOutput"
                value={amount}
                onChange={({ target: { value } }) => handleChangeAmount(value)}
              />
            </div>
            <div className="x-calculate-sliders-control">
              <div className="x-calculate-sliders-control">
                <InputRange
                  type="range"
                  name="value-range"
                  min="0"
                  max="100000"
                  step="100"
                  value={amount}
                  asset={activeAsset}
                  onChange={({ target: { value } }) => handleChangeAmount(value)}
                />
                <span className="x-calculate-sliders-control__values">
                  <span className="x-calculate-sliders-control__value">0 {activeTicker}</span>
                  <span className="x-calculate-sliders-control__value">100,000 {activeTicker}</span>
                </span>
              </div>
            </div>
          </div>

          <div className="x-calculate-sliders__slider">
            <div className="x-calculate-sliders__slider-title">
              <div className="x-calculate-sliders__heading">Term in months</div>
              <input
                className="x-calculate-sliders__value"
                id="monthsOutput"
                value={month}
                onChange={({ target: { value } }) => handleChangeMonth(value)}
              />
            </div>
            <div className="x-calculate-sliders-control">
              <div className="x-calculate-sliders-control">
                <div className="x-calculate-sliders-control">
                  <InputRange
                    type="range"
                    name="month-range"
                    step="1"
                    min="1"
                    max="24"
                    value={monthToDisplay}
                    asset={activeAsset}
                    onChange={({ target: { value } }) => handleChangeMonth(value)}
                  />
                  <span className="x-calculate-sliders-control__values">
                    <span className="x-calculate-sliders-control__value">1 months</span>
                    <span className="x-calculate-sliders-control__value">24 months</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {activeAsset.compoundApy && activeAsset.compoundApy > 0 && (
            <div className="x-calculate-sliders__compound">
              <RestakeIcon />
              <div className="x-calculate-sliders__compound__copy">
                <p>
                  <strong>Calculate With Restaking APY</strong>
                </p>
                <p>
                  <span>
                    Restaking APY is up to {activeAsset.compoundApy}%, instead of the regular{' '}
                    {activeAsset.apy}%.
                  </span>
                  <ToggleButton
                    colors={activeAsset.colors.gradient}
                    isChecked={compoundIsActive}
                    onChange={(checked) => setCompoundIsActive(checked)}
                  />
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="x__earn-crypto-page-sliders-results">
          <div className="x__earn-crypto-page-sliders-results__item">
            <img src={activeAsset.icon} alt={activeAsset.name} />

            <span className="x__earn-crypto-page-sliders-results__label">Average Reward Rate</span>
            <span className="x__earn-crypto-page-sliders-results__value">{activeRate}%</span>
          </div>
          <div className="x__earn-crypto-page-sliders-results__item">
            <span className="x__earn-crypto-page-sliders-results__label">Potential Reward</span>

            <span
              className="x__earn-crypto-page-sliders-results__value"
              style={{ color: activeAsset.colors.primary }}
            >
              {/* the last class needs to be dynamic */}+
              {calculatedAmount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{' '}
              <span>
                {activeTicker === 'VET' ? 'VTHO' : activeTicker === 'ONT' ? 'ONG' : activeTicker}
              </span>
            </span>
            <span className="x__earn-crypto-page-sliders-results__subnote">
              +{formatterCurrency.format(activePrice * calculatedAmount)} USD
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const TabItem = ({ asset, isActive, decreaseTabSize, onClick }) => {
  const { name, ticker, icon } = asset

  return (
    <div
      onClick={() => onClick(ticker)}
      className={classNames('x__earn-crypto-page-tabs-item', {
        'x__earn-crypto-page-tabs-item--active': isActive,
      })}
    >
      <img src={icon} alt={`Earn ${name} (${ticker}) rewards`} />
      <span className="x__earn-crypto-page-tabs-item__asset">
        {!decreaseTabSize && <span className="x__earn-crypto-page-tabs-item__name">{name}</span>}
        <span
          className={classNames('x__earn-crypto-page-tabs-item__ticker', {
            'x__earn-crypto-page-tabs-item__ticker--increased': decreaseTabSize,
          })}
        >
          {ticker}
        </span>
      </span>
    </div>
  )
}

const DropdownItemInner = ({ name, ticker, apy, icon, apyRate }) => (
  <>
    <img src={icon} alt={ticker} />

    <span className="x__earn-crypto-page__dropdown__asset">
      <span className="x__earn-crypto-page__dropdown__asset-name">{name}</span>
      <span className="x__earn-crypto-page__dropdown__asset-ticker">{ticker}</span>
    </span>

    <span className="x__earn-crypto-page__dropdown__asset-apy">APY {apy}%</span>
  </>
)

const DropdownToggler = ({ asset, compoundIsActive }) => {
  const { name, ticker, compoundApy, apy, icon } = asset

  return (
    <span className="x__earn-crypto-page__dropdown__item">
      <DropdownItemInner
        name={name}
        ticker={ticker}
        apy={compoundIsActive ? compoundApy : apy}
        icon={icon}
      />
    </span>
  )
}

const DropdownItem = ({ asset, isActive, compoundIsActive, onClick }) => {
  const { name, ticker, compoundApy, apy, icon } = asset

  return (
    <Dropdown.Item
      className={classNames('x__earn-crypto-page__dropdown__item', {
        'x__earn-crypto-page__dropdown__item--active': isActive,
      })}
      onClick={() => onClick(ticker)}
    >
      <DropdownItemInner
        name={name}
        ticker={ticker}
        apy={compoundIsActive ? compoundApy : apy}
        icon={icon}
      />
    </Dropdown.Item>
  )
}

export default ProfitCalculator
