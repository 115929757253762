// dependencies.
import React from 'react'

// Styles & Images.
import 'static/components/input-range/scss/styles.scss'

// Main component:
const InputRange = ({ value, asset, max, min, ...rest }) => {
  const trackStyle = {
    background: `linear-gradient(to right, ${asset.colors.gradient[1] || '#fbcc5f'}, ${
      asset.colors.gradient[0] || '#f9a606'
    })`,
    transform: `translateX(${(value / max) * 100}%)`,
  }

  return (
    <div className="input-range">
      <div className="bg" />

      <div className="fill-wrapper">
        <div className="fill" style={trackStyle} />
      </div>

      <input type="range" value={value} max={max} min={min} {...rest} />
    </div>
  )
}

export default InputRange
