import { useMemo } from 'react'

import prepareApyAssetList from 'src/js/assets/prepareApyAssetList'
import useFetchAssetsData from 'src/js/assets/hooks/useFetchAssetsData'

const useFetchApyAssets = (dataObjects) => {
  const { withApy, withPrice, withCurrentPrice, err } = useFetchAssetsData()

  // build assets array.
  const { assets, count } = useMemo(() => {
    return prepareApyAssetList(dataObjects, withApy, withPrice, withCurrentPrice)
  }, [dataObjects, withApy, withPrice, withCurrentPrice])

  // if error, return with log.
  if (err) return console.error(err)

  return { assets, count }
}

export default useFetchApyAssets
