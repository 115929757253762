// dependencies.
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// components.
import PlatformDropdown, { platforms } from 'src/components/button/Download/PlatformDropdown'

// Styles & Images
import 'src/components/pages/earn-crypto/scss/HeaderSection.scss'

const HeaderSection = ({ data, page, location }) => {
  // Download links.
  const downloadDropdownProps = {
    colors: ['#A16BB3', '#420BE6'],
    trackerPage: 'EarnCryptoPage',
    location,
  }

  const googleDownloadProps = {
    utmSource: 'exodus-website',
    utmCampaign: `${page}-wallet`,
    utmContent: `${page}-wallet`,
  }

  return (
    <header className="x__earn-crypto-page__header">
      <div className="x__earn-crypto-page__header__content">
        <StaticImage
          src="./img/earn-crypto-logo.svg"
          alt={data.alt}
          className="x__earn-crypto-page__header__logo"
        />

        <h1 className="x__earn-crypto-page__header__heading">
          <span dangerouslySetInnerHTML={{ __html: data.h1 }} />
        </h1>

        <h2
          className="x__earn-crypto-page__header__subheading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />

        <div className="x__earn-crypto-page__header__actions">
          <PlatformDropdown platform={platforms.desktop} {...downloadDropdownProps} />
          <PlatformDropdown
            platform={platforms.mobile}
            androidLinkProps={googleDownloadProps}
            {...downloadDropdownProps}
          />
          <PlatformDropdown
            platform={platforms.web3}
            extensionLinkProps={googleDownloadProps}
            {...downloadDropdownProps}
          />
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
