// dependencies.
import React, { useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import PageHead from 'src/components/head/PageHead'
import { Header, Footer } from 'src/components/layout'
import {
  AssetsSection,
  CalculatorSection,
  CarouselSection,
  HeaderSection,
} from 'src/components/pages/earn-crypto'
// utils.
import useFetchApyAssets from 'src/js/assets/hooks/useFetchApyAssets'
import { useDimensions, useRellax, useScrollToAnchor } from 'js/utils/hooks'
import { useAssets } from 'js/utils/hooks/useAssetsBase'
import { useIcons } from 'js/utils/hooks/useIcons'
// data.
import data from 'src/data/pages/Contents/earn-crypto.json'

// Styles & Images
import 'src/components/pages/earn-crypto/scss/DisclosureSection.scss'
import 'static/rewards/scss/styles.scss'

// Helpers:
const filterAssetsWithApy = (arr, assetList) =>
  arr.length > 0 &&
  arr
    .map((asset) => assetList.includes(asset.ticker) && asset)
    .filter((el) => el !== false)
    .sort((a, b) => b.marketcap - a.marketcap)

// Main component:
const Rewards = ({ pageName = 'earn-crypto', pageData = data, location }) => {
  // get page data.
  const page = pageName
  const { assetList, content } = pageData
  const { header, assetsWithAPY, disclosure, calculator, carousel } = content

  const backgroundRef = useRef(null)

  const { width } = useDimensions()

  const assetsData = useAssets()
  const iconsData = useIcons()

  const dataObjects = { assetsData, iconsData }

  const { assets } = useFetchApyAssets(dataObjects)
  let filteredAssetsWithApy = filterAssetsWithApy(assets, assetList)

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      filteredAssetsWithApy = filterAssetsWithApy(assets, assetList)
    }, 1000 * 60)

    return clearInterval(fetchInterval)
  }, [assets, assetList])

  const hasRates =
    filteredAssetsWithApy.length > 0 &&
    filteredAssetsWithApy.every((obj) => Object.prototype.hasOwnProperty.call(obj, 'apy'))

  const hasPrices =
    filteredAssetsWithApy.length > 0 &&
    filteredAssetsWithApy.every(
      (obj) =>
        Object.prototype.hasOwnProperty.call(obj, 'price') &&
        Object.prototype.hasOwnProperty.call(obj.price, 'current')
    )

  useScrollToAnchor(hasRates && hasPrices)

  useRellax({ ref: backgroundRef, center: false })

  // get images.
  const images = useStaticQuery(graphql`
    query {
      background400: file(relativePath: { regex: "/^rewards\/img\/header-bg-ssize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 400, placeholder: BLURRED) }
      }
      background800: file(relativePath: { regex: "/^rewards\/img\/header-bg-msize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 800, placeholder: BLURRED) }
      }
      background1600: file(relativePath: { regex: "/^rewards\/img\/header-bg-lsize\\.jpg$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      rewardsSlide1: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide2: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide3: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide4: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_4\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      rewardsSlide5: file(relativePath: { regex: "/^rewards\/img\/rewards\/slide_5\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      restakingSlide1: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      restakingSlide2: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      restakingSlide3: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      restakingSlide4: file(relativePath: { regex: "/^rewards\/img\/restaking\/slide_4\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }

      ethstakingSlide1: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_1\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      ethstakingSlide2: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_2\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
      ethstakingSlide3: file(relativePath: { regex: "/^rewards\/img\/ethstaking\/slide_3\\.png$/" }) {
        childImageSharp { gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED) }
      }
    }
  `)

  const getBackgroundImage = () => {
    if (width && width <= 400) {
      return images.background400.childImageSharp.gatsbyImageData
    } else if (width && width <= 800) {
      return images.background800.childImageSharp.gatsbyImageData
    } else {
      return images.background1600.childImageSharp.gatsbyImageData
    }
  }

  // carousel images.
  const carouselImageMap = {
    rewardsCarousel: [
      images.rewardsSlide1.childImageSharp.gatsbyImageData,
      images.rewardsSlide2.childImageSharp.gatsbyImageData,
      images.rewardsSlide3.childImageSharp.gatsbyImageData,
      images.rewardsSlide4.childImageSharp.gatsbyImageData,
      images.rewardsSlide5.childImageSharp.gatsbyImageData,
    ],
    restakingCarousel: [
      images.restakingSlide1.childImageSharp.gatsbyImageData,
      images.restakingSlide2.childImageSharp.gatsbyImageData,
      images.restakingSlide3.childImageSharp.gatsbyImageData,
      images.restakingSlide4.childImageSharp.gatsbyImageData,
    ],
    ethstakingCarousel: [
      images.ethstakingSlide1.childImageSharp.gatsbyImageData,
      images.ethstakingSlide2.childImageSharp.gatsbyImageData,
      images.ethstakingSlide3.childImageSharp.gatsbyImageData,
    ],
  }

  const checkEthExists =
    assetsWithAPY.length > 0 && assetsWithAPY.some((asset) => asset.ticker === 'ETH')

  const carouselData = carousel
    .map((item) => {
      const key = Object.keys(item)[0]
      const imagesArray = carouselImageMap[key] || []
      const maybeExcludeEth = key === 'ethstakingCarousel' && !checkEthExists
      return !maybeExcludeEth ? { ...item[key], images: imagesArray } : null
    })
    .filter((el) => el !== null)

  // Return content.
  return (
    <div className="x__earn-crypto-page">
      <Header background="black" />

      <main className="x">
        <div
          ref={backgroundRef}
          className="x__earn-crypto-page__background-container"
          data-rellax-speed="-4"
          role="presentation"
        >
          <div className="x__earn-crypto-page__background">
            <GatsbyImage image={getBackgroundImage()} alt={header.alt} loading="eager" />
          </div>
        </div>

        {header && <HeaderSection data={header} page={page} location={location} />}

        {assetsWithAPY && <AssetsSection data={assetsWithAPY} assets={filteredAssetsWithApy} />}

        <span
          className="x__earn-crypto-page__disclosure"
          dangerouslySetInnerHTML={{ __html: disclosure }}
        />

        {calculator && <CalculatorSection data={calculator} assets={filteredAssetsWithApy} />}

        {carouselData.length > 0 &&
          carouselData.map((carouselItem, i) => (
            <CarouselSection key={i + 1} data={carouselItem} variant={i + 1} />
          ))}
        <Footer />
      </main>
    </div>
  )
}

export default Rewards

// <head> component:
export function Head() {
  return <PageHead page="earn-crypto" />
}
