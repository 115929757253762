// dependencies.
import React from 'react'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
// components.
import AppsCarousel from 'src/components/carousel/AppsCarousel'
// utils.
import useDimensions from 'js/utils/hooks/useDimensions'

// Styles & Images
import 'src/components/pages/earn-crypto/scss/CarouselSection.scss'

// Partials:
const Chromes = ({ backgroundImage = '', amount = 3 }) => {
  const chromesArr = Array.from({ length: amount }, (_, index) => index)
  const baseClassName = `x__earn-crypto-page__carousel__${
    backgroundImage ? 'custom-chrome' : 'chrome'
  }`

  const style = {
    ...(backgroundImage ? { style: { backgroundImage: `url(${backgroundImage})` } } : {}),
  }

  if (chromesArr.length <= 0) return null

  return chromesArr.map((chrome, i) => (
    <span key={i} className={`${baseClassName} ${baseClassName}--${i}`} {...style} />
  ))
}

// Main component:
const CarouselSection = ({ data, variant = 1 }) => {
  const { isMobile } = useDimensions

  if (!data || !data.images.length) return null

  // Return content.
  return (
    <section
      className={classNames('x__earn-crypto-page__carousel', {
        [`x__earn-crypto-page__carousel--${variant}`]: variant,
        'x__earn-crypto-page__carousel--custom-chromes': data.chromes,
      })}
    >
      <div className="x__earn-crypto-page__carousel__content">
        {data.h2 && <h2 className="x__earn-crypto-page__carousel__heading">{data.h2}</h2>}
        {data.h3 && (
          <h3
            className="x__earn-crypto-page__carousel__subheading"
            dangerouslySetInnerHTML={{ __html: data.h3 }}
          />
        )}

        <Chromes />
        {data.chromes && <Chromes backgroundImage={data.chromes} />}

        <AppsCarousel id="x__earn-crypto-page__carousel" disableArrows={isMobile}>
          {data.images.map((image, i) => (
            <GatsbyImage key={i} image={image} alt={data.alt} loading="lazy" />
          ))}
        </AppsCarousel>

        {data.footnote && <p className="x__earn-crypto-page__carousel__note">{data.footnote}</p>}
      </div>
    </section>
  )
}

export default CarouselSection
