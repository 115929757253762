// dependencies.
import React, { useState, useEffect, useMemo, Fragment } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import classNames from 'classnames'
// components.
import AssetItemBackground from 'src/components/pages/earn-crypto/img/AssetItemBackground'
// utils.
import chunkArray from 'src/utils/chunkArray'

// Styles & Images
import 'src/components/pages/earn-crypto/scss/AssetsSection.scss'

// Helpers:
const getItemsPerRow = () => {
  if (typeof window === 'undefined') return 5 // prevent build from failing.
  return window.innerWidth < 1000 ? 1 : 5
}

const getInterval = () => {
  if (typeof window === 'undefined') return 5000 // prevent build from failing.
  return window.innerWidth < 1000 ? 1000 : 5000
}

// Partials:
const AssetItem = ({ data, loading }) => (
  <div
    className={classNames(`x__asset-item`, {
      'x__asset-item--placeholder': loading,
      [`x__asset-item--${data?.ticker?.toLowerCase()}`]: !loading && data.ticker,
    })}
  >
    {loading ? (
      <div className="x__earn-crypto-page__assets-with-apy__loader">
        <div className="x__earn-crypto-page__assets-with-apy__loader__inner">
          <span className="x__earn-crypto-page__assets-with-apy__loader__spinner" />
        </div>
      </div>
    ) : (
      <>
        <span className="x__asset-item__name">{data.name}</span>
        <span className="x__asset-item__ticker">({data.ticker})</span>
        <span className="x__asset-item__percent">{data.compoundApy || data.apy}%</span>
        <span className="x__asset-item__apy">APY*</span>
        <AssetItemBackground asset={data} />
      </>
    )}
  </div>
)

const AssetsCarousel = ({ assets = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [interval, setInterval] = useState(getInterval())
  const [itemsPerRow, setItemsPerRow] = useState(getItemsPerRow())

  const handleSelect = (selectedIndex, e) => setActiveIndex(selectedIndex)

  const checkAssetsExist = assets.length > 0
  const assetsArray = checkAssetsExist ? [...assets] : ['', '', '', '', ''] // placeholder for loading elements.

  // itemsPerRow changes as needed when window resizes.
  useEffect(() => {
    if (typeof window === 'undefined') return // prevent build from failing.

    const onResize = () => {
      setItemsPerRow(getItemsPerRow())
      setInterval(getInterval())
    }

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  // reset to position 0 when a window resize causes itemsPerRow to change.
  useEffect(() => {
    setActiveIndex(0)
  }, [itemsPerRow, interval])

  const chunkedData = useMemo(
    () => chunkArray(assetsArray, itemsPerRow),
    [assetsArray, itemsPerRow]
  )

  return (
    <Carousel
      className="x__earn-crypto-page__assets-with-apy__assets"
      controls={false}
      indicators={checkAssetsExist}
      activeIndex={activeIndex}
      onSelect={handleSelect}
    >
      {chunkedData.map((chunkedAssets, index) => (
        <Carousel.Item key={index} interval={interval}>
          <div className="x__earn-crypto-page__assets-with-apy__assets__wrapper">
            {chunkedAssets.map((assetObj, i) => (
              <AssetItem key={`${index}-${i}`} data={assetObj} loading={!checkAssetsExist} />
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

// Main component:
const AssetsSection = ({ data, assets = [] }) => (
  <section className="x__earn-crypto-page__assets-with-apy" id="calculate">
    <h2 className="x__earn-crypto-page__assets-with-apy__heading">{data.h2}</h2>

    <h3
      className="x__earn-crypto-page__assets-with-apy__subheading"
      dangerouslySetInnerHTML={{ __html: data.h3 }}
    />

    <AssetsCarousel assets={assets} />
  </section>
)

export default AssetsSection
